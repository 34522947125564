import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../../components/layout'

export default ({ data, pageContext: { nextPage, prevPage, isFirst } }) => (
  <Layout>
    {isFirst ? (
      <>
        <h1>Box Shadows</h1>
        <p>
          Explore our collection of hand curated box shadows for inspiration
        </p>
      </>
    ) : null}
    {data.boxShadows.nodes.map((boxShadow, i) => (
      <div
        key={i}
        css={{
          display: 'inline-block',
          height: 120,
          width: 120,
          margin: 20,
          boxShadow: boxShadow.value
        }}
      />
    ))}
    {prevPage && <Link to={prevPage}>Previous</Link>}
    {nextPage && <Link to={nextPage}>Next</Link>}
  </Layout>
)

export const pageQuery = graphql`
  query BoxShadowList($limit: Int, $skip: Int) {
    boxShadows: allBoxShadowsJson(limit: $limit, skip: $skip) {
      nodes {
        value
      }
    }
  }
`
